var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-button',{on:{"click":function($event){return _vm.$router.push('/sistemicos/modelos/novo')}}},[_vm._v("Novo Modelo")]),_c('Card',{staticClass:"py-3"},[_c('b-row',[_c('b-col',[_c('label',[_vm._v("Canal")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsChannels},model:{value:(_vm.selected.channel_id),callback:function ($$v) {_vm.$set(_vm.selected, "channel_id", $$v)},expression:"selected.channel_id"}})],1),(_vm.selected.channel_id == '1')?[_c('b-col',[_c('label',[_vm._v("Ativos")]),_c('b-form-select',{attrs:{"options":[
              { text: 'Todos', value: '' },
              { text: 'Somente Ativos', value: '1' },
              { text: 'Somente Inativos', value: '0' },
            ]},model:{value:(_vm.selected.active),callback:function ($$v) {_vm.$set(_vm.selected, "active", $$v)},expression:"selected.active"}})],1),_c('b-col',[_c('label',[_vm._v("Aplicação")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsUnityApplications},model:{value:(_vm.selected.application_id),callback:function ($$v) {_vm.$set(_vm.selected, "application_id", $$v)},expression:"selected.application_id"}})],1),_c('b-col',[_c('label',[_vm._v("Legados")]),_c('b-form-select',{attrs:{"options":[
              { text: 'Todos', value: '' },
              { text: 'Somente Legados', value: '1' },
              { text: 'Somente Não Legados', value: '0' },
            ]},model:{value:(_vm.selected.legacy),callback:function ($$v) {_vm.$set(_vm.selected, "legacy", $$v)},expression:"selected.legacy"}})],1),_c('b-col',[_c('label',[_vm._v("Tipo de Notificação")]),_c('b-form-select',{attrs:{"options":_vm.selectOptionsNotificationTypes},model:{value:(_vm.selected.notification_type_id),callback:function ($$v) {_vm.$set(_vm.selected, "notification_type_id", $$v)},expression:"selected.notification_type_id"}})],1)]:_vm._e()],2),_c('hr'),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{on:{"click":_vm.get_models}},[_vm._v("Filtrar")])],1)],1),_c('ModelsList')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }