<template>
  <div>
    <b-button @click="$router.push('/sistemicos/modelos/novo')">Novo Modelo</b-button>
    <Card class="py-3">
      <b-row>
        <b-col>
          <label>Canal</label>
          <b-form-select
            :options="selectOptionsChannels"
            v-model="selected.channel_id"
          ></b-form-select>
        </b-col>

        <template v-if="selected.channel_id == '1'">
          <b-col>
            <label>Ativos</label>
            <b-form-select
              :options="[
                { text: 'Todos', value: '' },
                { text: 'Somente Ativos', value: '1' },
                { text: 'Somente Inativos', value: '0' },
              ]"
              v-model="selected.active"
            >
            </b-form-select>
          </b-col>
          <b-col>
            <label>Aplicação</label>
            <b-form-select
              :options="selectOptionsUnityApplications"
              v-model="selected.application_id"
            ></b-form-select>
          </b-col>
          <b-col>
            <label>Legados</label>
            <b-form-select
              :options="[
                { text: 'Todos', value: '' },
                { text: 'Somente Legados', value: '1' },
                { text: 'Somente Não Legados', value: '0' },
              ]"
              v-model="selected.legacy"
            >
            </b-form-select>
          </b-col>
          <b-col>
            <label>Tipo de Notificação</label>
            <b-form-select
              :options="selectOptionsNotificationTypes"
              v-model="selected.notification_type_id"
            ></b-form-select>
          </b-col>
        </template>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button @click="get_models">Filtrar</b-button>
      </div>
    </Card>
    <ModelsList />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {
        channel_id: "",
        application_id: "",
        notification_type_id: "",
        profile_id: "",
        active: "",
        legacy: "",
      },
    };
  },
  components: {
    ModelsList: () => import("./components/ModelsList.vue"),
  },
  computed: {
    state() {
      return this.$store.state.modelsCustom;
    },
    applicationsById() {
      return _.keyBy(this.state.applications, "id");
    },
    selectOptionsUnityApplications() {
      const firstOption = { text: "Todas", value: "" };
      const normalized = _.map(this.state.unityApplications, (item) => {
        return {
          text: this.applicationsById[item.application_id].name,
          value: item.application_id,
        };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsNotificationTypes() {
      const firstOption = { text: "Todos", value: "" };
      const normalized = _.map(this.state.notificationTypes, (item) => {
        return { text: item.description, value: item.id };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsChannels() {
      const firstOption = {
        text: "Selecione um canal",
        value: "",
        disabled: true,
      };
      const normalized = _.map(this.state.channels, (item) => {
        return {
          text: item.channel,
          value: item.id,
          disabled: item.active == "1" ? false : true,
        };
      });
      return [firstOption, ...normalized];
    },
    models() {
      return this.state.templatesList;
    },
  },
  methods: {
    async get_models() {
      this.$store.dispatch("modelsCustom/get_models", this.selected);
    },
  },
  mounted() {
    this.$store.dispatch("modelsCustom/list_init");
  },
};
</script>

<style>
</style>